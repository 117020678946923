import { EprBannerDto } from 'types/dtos/epr-banner'
import { EprBannerModel } from 'types/models/epr-banner'

export const transformEprBanner = ({
  name,
  title,
  body,
  add_uin_button_label,
  add_uin_button_url,
  learn_more_button_label,
  learn_more_button_url,
}: EprBannerDto): EprBannerModel => ({
  name,
  title,
  body,
  addUinButtonLabel: add_uin_button_label,
  addUinButtonUrl: add_uin_button_url,
  learnMoreButtonLabel: learn_more_button_label,
  learnMoreButtonUrl: learn_more_button_url,
})
