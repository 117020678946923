import { PortalMergeDraftItemsReminderDto } from 'types/dtos/portal-merge-draft-items-reminder-banner'
import { PortalMergeDraftItemsReminderModel } from 'types/models/portal-merge-draft-items-reminder-banner'

export const transformPortalMergeDraftItemsReminder = ({
  title,
  subtitle,
  action_title,
  action_url,
}: PortalMergeDraftItemsReminderDto): PortalMergeDraftItemsReminderModel => ({
  title,
  subtitle,
  actionTitle: action_title,
  actionUrl: action_url,
})
