'use client'

import { useEffect, useState } from 'react'
import { Button, Cell, Divider, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X16 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { navigateToPage } from '@marketplace-web/shared/browser'
import {
  TAXPAYER_CENTER_URL_WITH_REF,
  TAXPAYER_EDUCATION_URL_WITH_REF,
  TAXPAYER_FORM_URL_WITH_REF,
} from 'constants/routes'
import { dismissTaxpayerRestrictionModal, getTaxpayerNavigationInfo } from 'data/api'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { taxpayersClickEvent, taxpayersViewEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import useRefUrl from 'hooks/useRefUrl'

import { getIsUserBusiness } from '../../../utils/taxpayer'

type Props = {
  show: boolean
  isSellingBlocked: boolean
  screen?: Screen
  onClose: () => void
}

const TaxpayerRestrictionInfoModal = ({ show, isSellingBlocked, screen, onClose }: Props) => {
  const translate = useTranslate('taxpayer_restriction_info_modal')
  const { track } = useTracking()
  const refUrl = useRefUrl()

  const [isTaxpayerNavigationLoading, setIsTaxpayerNavigationLoading] = useState(false)

  const title = isSellingBlocked
    ? translate('selling_blocked.title')
    : translate('restricted.title')
  const body = isSellingBlocked ? translate('selling_blocked.body') : translate('restricted.body')

  useEffect(() => {
    if (!show || !screen) return

    track(
      taxpayersViewEvent({
        screen,
        target: 'block_modal',
        target_details: isSellingBlocked ? 'sales' : 'balance',
      }),
    )
  }, [track, show, isSellingBlocked, screen])

  const handleFinaliseReport = () => {
    if (screen) {
      track(
        taxpayersClickEvent({
          screen,
          target: 'block_modal',
          target_details: 'finalise',
          target_name: isSellingBlocked ? 'sales' : 'balance',
        }),
      )
    }

    dismissTaxpayerRestrictionModal()
    navigateToPage(TAXPAYER_FORM_URL_WITH_REF(refUrl))
  }

  const handleLearnMore = async () => {
    if (screen) {
      track(
        taxpayersClickEvent({
          screen,
          target: 'block_modal',
          target_details: 'learn',
          target_name: isSellingBlocked ? 'sales' : 'balance',
        }),
      )
    }

    dismissTaxpayerRestrictionModal()
    setIsTaxpayerNavigationLoading(true)

    const response = await getTaxpayerNavigationInfo()

    if ('errors' in response) {
      setIsTaxpayerNavigationLoading(false)

      return
    }

    const isUserBusiness = getIsUserBusiness(response.user_type)

    if (isUserBusiness) {
      navigateToPage(TAXPAYER_EDUCATION_URL_WITH_REF(refUrl))
    } else {
      navigateToPage(TAXPAYER_CENTER_URL_WITH_REF(refUrl))
    }
  }

  const handleClose = () => {
    if (screen) {
      track(
        taxpayersClickEvent({
          screen,
          target: 'block_modal',
          target_details: 'close',
          target_name: isSellingBlocked ? 'sales' : 'balance',
        }),
      )
    }

    onClose()
  }

  return (
    <Dialog
      show={show}
      className="u-flexbox u-flex-direction-column"
      testId="taxpayer-restriction-modal"
    >
      <Navigation
        right={
          <Button
            styling={Button.Styling.Flat}
            onClick={handleClose}
            testId="taxpayer-restriction-modal-close"
            iconName={X16}
            inline
          />
        }
      />
      <Divider />
      <Cell>
        <Text as="h1" type={Text.Type.Heading} text={title} />
        <Spacer size={Spacer.Size.Large} />
        <Text as="span" type={Text.Type.Body} text={body} />
        <Spacer size={Spacer.Size.XLarge} />
        <Button
          styling={Button.Styling.Filled}
          text={translate('actions.finalise')}
          onClick={handleFinaliseReport}
        />
        <Spacer size={Spacer.Size.Large} />
        <Button
          styling={Button.Styling.Flat}
          text={translate('actions.learn_more')}
          onClick={handleLearnMore}
          isLoading={isTaxpayerNavigationLoading}
        />
      </Cell>
    </Dialog>
  )
}

export default TaxpayerRestrictionInfoModal
