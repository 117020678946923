import { CatalogRulesBannerDto } from 'types/dtos/catalog-rules-banner'
import { CatalogRulesBannerModel } from 'types/models/catalog-rules-banner'

export const transformCatalogRulesBanner = ({
  name,
  title,
  subtitle,
  action_title,
  action_url,
}: CatalogRulesBannerDto): CatalogRulesBannerModel => ({
  name,
  title,
  subtitle,
  actionTitle: action_title,
  actionUrl: action_url,
})
