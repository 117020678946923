import { NpsDto } from 'types/dtos/nps-banner'
import { NpsModel } from 'types/models/nps-banner'

export const transformNps = ({
  question_title,
  open_question_title,
  score_explanation,
}: NpsDto): NpsModel => ({
  questionTitle: question_title,
  openQuestionTitle: open_question_title,
  scoreExplanation: score_explanation,
})
