'use client'

import { useEffect, useState } from 'react'
import { Checkmark48 } from '@vinted/monochrome-icons'
import { Button, Cell, Dialog, Icon, Spacer, Text } from '@vinted/web-ui'

import { getSessionStorageItem, removeSessionStorageItem } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { clickEvent, taxpayersViewScreenEvent, viewEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import { SPECIAL_VERIFICATION_SESSION_ID_KEY } from '../../pages/TaxpayersSpecialVerificationForm/constants'

const TaxpayersSpecialVerificationSuccessModal = () => {
  const translate = useTranslate('taxpayer_special_verification.success_modal')
  const { track } = useTracking()

  const specialVerificationSessionId = getSessionStorageItem(SPECIAL_VERIFICATION_SESSION_ID_KEY)

  const [show, setShow] = useState(!!specialVerificationSessionId)

  useEffect(() => {
    if (!show) return

    track(
      viewEvent({
        screen: Screen.TaxpayersSpecialVerificationSuccess,
        target: ViewableElement.VerificationCompleted,
        targetDetails: JSON.stringify({ verification_id: specialVerificationSessionId }),
      }),
    )
    track(
      taxpayersViewScreenEvent({
        screen: Screen.TaxpayersSpecialVerificationSuccess,
        details: null,
      }),
    )

    removeSessionStorageItem(SPECIAL_VERIFICATION_SESSION_ID_KEY)
  }, [show, specialVerificationSessionId, track])

  const handleClose = () => {
    track(
      clickEvent({
        target: ClickableElement.FinishSpecialVerification,
        screen: Screen.TaxpayersSpecialVerificationSuccess,
      }),
    )

    setShow(false)
  }

  const renderHeader = () => {
    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Icon name={Checkmark48} color={Icon.Color.Success} />
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <Text
          as="h1"
          alignment={Text.Alignment.Center}
          type={Text.Type.Heading}
          text={translate('title')}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          as="span"
          alignment={Text.Alignment.Center}
          type={Text.Type.Body}
          text={translate('body')}
        />
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        <Spacer size={Spacer.Size.Large} />
        <Button
          styling={Button.Styling.Filled}
          text={translate('actions.finish')}
          testId="special-verification-finish-button"
          onClick={handleClose}
        />
      </>
    )
  }

  return (
    <Dialog show={show} className="u-text-center">
      {renderHeader()}
      <Cell>
        {renderBody()}
        {renderFooter()}
      </Cell>
    </Dialog>
  )
}

export default TaxpayersSpecialVerificationSuccessModal
